import { render, staticRenderFns } from "./RadarChart.vue?vue&type=template&id=eb270d56&"
import script from "./RadarChart.vue?vue&type=script&lang=ts&"
export * from "./RadarChart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('line-chart-generator', {
    attrs: {
      "chart-data": _vm.chartData,
      "chart-options": _vm.chartOptions,
      "styles": _vm.chartStyles
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
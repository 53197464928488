








import Vue from 'vue'
import Component from 'vue-class-component'
import { Bar, Line as LineChartGenerator } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, LineElement, CategoryScale, LinearScale, PointElement)

@Component({
  components: { Bar, LineChartGenerator }
})
export default class BarChart extends Vue {
  chartData = {
    labels: [ 'January', 'February', 'March' ],
    datasets: [ 
      { label: 'Test', data: [40, 20, 12], backgroundColor: '#c80000a1', borderRadius: 8 },
      { label: 'Test 2', data: [20, 12, 40], backgroundColor: '#3181d3a1', borderRadius: 8 },
      { label: 'Test 3', data: [20, 40, 12], backgroundColor: '#46bc82a1', borderRadius: 8 },
    ]
  }
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    }
  }
  chartStyles = {
    height: '100%'
  }
}










import Vue from 'vue'
import Component from 'vue-class-component'
import { Doughnut } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

@Component({
  components: { Doughnut }
})
export default class DoughnutChart extends Vue {
  chartData = {
    labels: ['Test', 'Test 2', 'Test 3'],
    datasets: [ 
      {
        backgroundColor: ['#c80000a1', '#46bc82a1', '#3181d3a1'],
        data: [20, 80, 10]
      },
    ]
  }
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  }
  chartStyles = {
    height: '100%'
  }
}

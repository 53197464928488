








import Vue from 'vue'
import Component from 'vue-class-component'
import { Radar } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, RadialLinearScale, Filler } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, RadialLinearScale, Filler)

@Component({
  components: { Radar }
})
export default class RadarChart extends Vue {
  chartData = {
    labels: ['Test', 'Test 2', 'Test 3'],
    datasets: [ 
      {
        label: 'Test',
        data: [20, 50, 60],
        backgroundColor: '#c80000a1',
      },
      {
        label: 'Test 2',
        backgroundColor: '#46bc82a1',
        fill: true,
        data: [70, 25, 40]
      },
      {
        label: 'Test 3',
        backgroundColor: '#3181d3a1',
        fill: true,
        data: [40, 60, 30]
      },
    ]
  }
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  }
  chartStyles = {
    height: '100%'
  }
}










import Vue from 'vue'
import Component from 'vue-class-component'
import { Bar, Line as LineChartGenerator } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LineElement, CategoryScale, LinearScale, PointElement, Filler } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, LineElement, CategoryScale, LinearScale, PointElement, Filler)

@Component({
  components: { Bar, LineChartGenerator }
})
export default class LineChart extends Vue {
  chartData = {
    labels: [ 'January', 'February', 'March', 'April', 'May' ],
    datasets: [ 
      { label: 'Test', data: [2, 12, 6, 4, 10], backgroundColor: '#c80000a1', borderColor: '#c80000a1', pointRadius: 6, tension: 0.5 },
      { label: 'Test 2', data: [4, 1, 8, 9, 3], backgroundColor: '#3181d3a1', borderColor: '#3181d3a1', pointRadius: 6, tension: 0.5 },
      { label: 'Test 3', data: [1, 2, 6, 4, 12], backgroundColor: '#46bc82a1', borderColor: '#46bc82a1', pointRadius: 6, tension: 0.5 },
    ]
  }
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    }
  }
  chartStyles = {
    height: '100%'
  }
}

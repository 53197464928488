
















































import Vue from 'vue'
import Component from 'vue-class-component'
import BarChart from './test-graphs/BarChart.vue'
import LineChart from './test-graphs/LineChart.vue'
import BubbleChart from './test-graphs/BubbleChart.vue'
import DoughnutChart from './test-graphs/DoughnutChart.vue'
import PieChart from './test-graphs/PieChart.vue'
import PolarAreaChart from './test-graphs/PolarAreaChart.vue'
import RadarChart from './test-graphs/RadarChart.vue'

@Component({
  components: { BarChart, LineChart, BubbleChart, DoughnutChart, PieChart, PolarAreaChart, RadarChart }
})
export default class TestGraphs extends Vue {
}

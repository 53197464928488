var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "test-graphs"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "test-graphs__container"
  }, [_c('div', {
    staticClass: "test-graphs__box"
  }, [_c('div', {
    staticClass: "test-graphs__box-inner"
  }, [_c('bar-chart')], 1)]), _c('div', {
    staticClass: "test-graphs__box"
  }, [_c('div', {
    staticClass: "test-graphs__box-inner"
  }, [_c('line-chart')], 1)]), _c('div', {
    staticClass: "test-graphs__box"
  }, [_c('div', {
    staticClass: "test-graphs__box-inner"
  }, [_c('bubble-chart')], 1)]), _c('div', {
    staticClass: "test-graphs__box"
  }, [_c('div', {
    staticClass: "test-graphs__box-inner"
  }, [_c('doughnut-chart')], 1)]), _c('div', {
    staticClass: "test-graphs__box"
  }, [_c('div', {
    staticClass: "test-graphs__box-inner"
  }, [_c('pie-chart')], 1)]), _c('div', {
    staticClass: "test-graphs__box"
  }, [_c('div', {
    staticClass: "test-graphs__box-inner"
  }, [_c('polar-area-chart')], 1)]), _c('div', {
    staticClass: "test-graphs__box"
  }, [_c('div', {
    staticClass: "test-graphs__box-inner"
  }, [_c('radar-chart')], 1)])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
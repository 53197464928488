








import Vue from 'vue'
import Component from 'vue-class-component'
import { Bubble } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, LinearScale, PointElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LinearScale, PointElement)

@Component({
  components: { Bubble }
})
export default class BubbleChart extends Vue {
  chartData = {
    datasets: [ 
      {
        label: 'Test',
        backgroundColor: '#c80000a1',
        data: [
          {
            x: 20,
            y: 25,
            r: 5
          },
          {
            x: 40,
            y: 10,
            r: 10
          },
          {
            x: 30,
            y: 22,
            r: 30
          }
        ]
      },
      {
        label: 'Test 2',
        backgroundColor: '#3181d3a1',
        data: [
          {
            x: 10,
            y: 30,
            r: 15
          },
          {
            x: 20,
            y: 20,
            r: 10
          },
          {
            x: 15,
            y: 8,
            r: 30
          }
        ]
      },
      {
        label: 'Test 3',
        backgroundColor: '#46bc82a1',
        data: [
          {
            x: 5,
            y: 25,
            r: 15
          },
          {
            x: 25,
            y: 10,
            r: 15
          },
          {
            x: 30,
            y: 25,
            r: 20
          }
        ]
      },
    ]
  }
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    }
  }
  chartStyles = {
    height: '100%'
  }
}
